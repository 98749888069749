@font-face {
  font-family: "Roboto";
  src:
    local("Roboto"),
    url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Roboto";
  src:
    local("Roboto"),
    url(./assets/fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: bold;
}

:root {
  --roboto: Roboto;
}
