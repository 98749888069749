:root {
  --primary-white: #ffffff;
  --primary-black: #000000;
  --primary-blue: #154392;
  --secondary-blue: #2f80ed;
  --primary-lightblue: #e7ecf5;

  //Text Color
  --input-field-placeholder-text-1: #9ca3af;
  --input-field-placeholder-text-2: #bdbdbd;
  --secondary-text-color: #6b7280;
  --testimonial-description-color: #828282;
  --textarea-placeholder-color: #2d9cdb;
  --sub-tab-text-color: #455a64;
  --investment-description: #4f4f4f;
  --table-text-color: #333333;
  --success-green-text: #1a8030;
  --blog-title-color: #2c2c2c;
  --blog-date-time-text-color: #828282;

  // Background Color
  --app-background: #f8f8f8;
  --input-box-bg-1: #f9fafb;
  --carousel-indicators-bg-1: linear-gradient(0deg, #154392, #154392), #d9d9d9;
  --top-bar-btn-bg: rgba(21, 67, 146, 0.1);
  --sidebar-nav-btn-selected-bg: rgba(21, 67, 146, 0.05);
  --testimonial-card-bg: linear-gradient(
    140.21deg,
    #ffffff 4.16%,
    #f3f3f3 94.74%
  );
  --share-experience-submit-btn-bg: linear-gradient(0deg, #154392, #154392);
  --close-btn-bg: #f2f2f2;
  --home-stat-section-bg: linear-gradient(90deg, #154392 32.16%, #2e99e6 94.74%),
    rgba(66, 127, 232, 0.06);
  --home-withdraw-section-bg: linear-gradient(
      140.21deg,
      #fd814d 4.16%,
      #ffb495 94.74%
    ),
    rgba(66, 127, 232, 0.06);
  --home-stat-get-started-btn: rgba(255, 255, 255, 0.3);
  --download-report-btn-bg: rgb(21, 67, 146, 0.1);
  --disabled-btn-bg: #d9d9d9;
  --asset-progress-bar-bg: #6fcf97;
  --asset-progress-indicator-bg: #219653;
  --completed-status-btn-bg: #04bc00;
  --closed-status-btn-bg: #eb5757;
  --investor-portfolio-card-bg-1: rgba(255, 255, 255, 0.7);
  --investment-status-success-bg: #5bb85d;
  --investment-status-warning-bg: #eed202;
  --investment-status-danger-bg: #c45a5a;
  --success-green-bg: #1a80301a;
  --chip-blue-bg: #72acee;
  --offer-alert-bg-color: #ddfbef;
  --primary-bg-light: #15439233;

  //Gradient Color
  --primary-btn-bg: linear-gradient(140.21deg, #154392 4.16%, #2e99e6 94.74%);
  --secondary-btn-bg: linear-gradient(179deg, #154392 0%, #2e99e5 100%);

  //Border Color
  --social-btn-border-1: #e5e7eb;
  --home-stat-divider-1: #dcdcdc;
  --divider-color-1: #f3f4f6;
  --divider-color-2: #a9a9a9;
  --table-border: #15439250;

  //Icon Color
  --star-icon-color-inactive-1: #959595;
  --star-icon-color-active-1: #ffd600;
  --card-nav-icon-color: #e0e0e0;
  --discount-icon-color: #1da16f;
}
