/* You can add global styles to this file, and also import other style files */
@import "/src/color.scss";
@import "/src/font.scss";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

html,
body {
  height: fit-content;
  background-color: var(--app-background);
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.cursor-pointer {
  cursor: pointer !important;
}

.primary-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 22px 10px;
  height: 66px;
  background: var(--primary-btn-bg);
  border-radius: 16px;
  outline: none;
  border: 0px;
  color: var(--primary-white);
  text-align: center;
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
}

.secondary-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 50px;
  gap: 16px;
  height: 61px;
  background: var(--primary-blue);
  border-radius: 16px;
  outline: none;
  border: 0px;
  color: var(--primary-white);
  text-align: center;
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
}

.disabled-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 50px;
  gap: 16px;
  height: 61px;
  background: var(--disabled-btn-bg);
  border-radius: 16px;
  outline: none;
  border: 0px;
  color: var(--primary-white);
  text-align: center;
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
}

button:disabled {
  opacity: 0.6;
}

.text-underline {
  text-decoration: underline;
}

.page-title {
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  color: var(--primary-blue);
  margin: 20px 0 20px 32px;
}

.authentication-page {
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  .arrow-back {
    user-select: none;
    cursor: pointer;
    margin: 56px 0px 0px 46px;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    background-color: var(--primary-white);
    border-radius: 14px;
    width: 452px;
    height: auto;
    padding: 38px 44px 36px 44px;
    margin: 60px;
    justify-content: space-between;

    .title {
      font-family: var(--roboto);
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 38px;
      color: var(--primary-black);
      user-select: none;
      margin-bottom: 30px;
    }

    .sub-title {
      font-family: var(--roboto);
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 38px;
      color: var(--secondary-text-color);
      margin-bottom: 16px;
    }

    .terms-title {
      font-family: var(--roboto);
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: var(--primary-blue);
    }

    .description {
      font-family: var(--roboto);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: var(--secondary-text-color);
      margin: 9px 0px 15px 0px;
    }

    .divider {
      height: 0px;
      width: 100%;
      border: 1px solid var(--divider-color-1);
      margin: 25px 0px 32px 0px;
    }

    .read-more {
      font-family: var(--roboto);
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      display: flex;
      align-items: center;
      align-self: end;
      color: var(--primary-blue);
      cursor: pointer;
      text-decoration: none;
    }

    .form-error {
      font-family: var(--roboto);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin: 5px 0px 10px 0px;
    }

    .input-label {
      font-family: var(--roboto);
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: var(--primary-black);
      margin-bottom: 8px;
    }

    .input-box {
      background: var(--input-box-bg-1);
      border-radius: 16px;
      height: 66px;
      max-height: 66px;
      border: 0px;
      font-family: var(--roboto);
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      color: var(--primary-black);
      outline: none;
      padding: 0px 20px;
      display: flex;
      flex-direction: row;

      mat-form-field {
        width: 100%;
      }

      .eye-icon {
        width: 35px;
        height: 30px;
        font-size: 30px;
        line-height: 34px;
        align-self: center;
        cursor: pointer;
        opacity: 0.5;
      }
    }

    .input-box + .input-box {
      margin-top: 16px;
    }

    .forgot-password {
      font-family: var(--roboto);
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 38px;
      color: var(--primary-blue);
      cursor: pointer;
      user-select: none;
      margin: 16px;
    }

    .resend {
      font-family: var(--roboto);
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 38px;
      color: var(--primary-blue);
      cursor: pointer;
      user-select: none;
      margin-bottom: 16px;
      align-self: flex-end;
    }

    .otp-on-mail {
      font-family: var(--roboto);
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
      color: var(--input-field-placeholder-text-1);
      cursor: pointer;
      user-select: none;
    }

    .by-continue {
      font-family: var(--roboto);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.408px;
      color: var(--secondary-text-color);
      margin-bottom: 32px;

      .term-and-condition {
        color: var(--primary-blue);
        cursor: pointer;
      }
    }

    .or {
      font-family: var(--roboto);
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.408px;
      text-align: center;
      color: var(--secondary-text-color);
      margin: 25px 0px;
      user-select: none;
    }

    .social-signup-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--social-btn-border-1);
      border-radius: 16px;
      height: 66px;
      width: -webkit-fill-available;
      cursor: pointer;
      user-select: none;
    }

    .social-signup-btn + .social-signup-btn {
      margin-left: 8px;
    }

    .form-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 46px;

      .have-an-account {
        font-family: var(--roboto);
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.408px;
        color: var(--secondary-text-color);
        margin-right: 8px;
        user-select: none;
      }

      .sign-up {
        font-family: var(--roboto);
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.408px;
        color: var(--primary-blue);
        user-select: none;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .form-container {
      margin: 0px;
      width: 100%;
    }
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    .form-container {
      width: 80%;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 991px) {
    .form-container {
      width: 60%;
    }
  }

  @media screen and (min-width: 991px) and (max-width: 1339px) {
    .form-container {
      margin: 60px 40px 60px 40px;
    }
  }
}
