@font-face {
  font-family: "Roboto";
  src:
    local("Roboto"),
    url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Roboto";
  src:
    local("Roboto"),
    url(./assets/fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "OpenSans";
  src:
    local("OpenSans"),
    url(./assets/fonts/OpenSans.ttf) format("truetype");
}

:root {
  --roboto: Roboto;
  --poppins: Poppins;
  --opensans: OpenSans;
}
